<template>
  <main
    class="main bg-brand-color overflow-hidden flex relative justify-center items-center w-ful h-screen py-12"
  >
    <div class="container">
      <div class="flex flex-col justify-center items-center">
        <img class="logo-image mb-8" :src="lightLogo" alt="logo" />
        <h3
          class="text-white text-4xl font-light mb-12 mt-2 text-center uppercase"
          >{{ formHeader }}</h3
        >
      </div>
      <form @submit.prevent="handleFormSubmit" v-show="showSignIn">
        <div class="flex flex-col">
          <label
            class="text-white font-light opacity-60 text-2xl"
            for="username-input"
            >Email</label
          >
          <input
            required
            id="username-input"
            v-model="formData.emailAddress"
            style="border-bottom: 1px white solid"
            class="bg-transparent border-b border-b-white border-b-solid text-3xl text-white mt-2 py-2 rounded-none focus:outline-none"
            type="email"
          />
        </div>
        <pm-button
          v-if="!loading"
          type="submit"
          background="solid-light"
          :gutters="true"
          size="small"
          >CONTINUE</pm-button
        >
        <pm-button background="solid-light" size="small" :gutters="true" v-else
          >PLEASE WAIT...</pm-button
        >
      </form>
      <need-help v-show="showSignIn"></need-help>
      <form @submit.prevent="handleFormSubmit" v-show="showLogin">
        <div class="flex flex-col">
          <label
            class="text-white font-light opacity-60 text-2xl"
            for="username-input"
            >Email</label
          >
          <input
            required
            id="username-input"
            v-model="formData.emailAddress"
            style="border-bottom: 1px white solid"
            class="bg-transparent border-b border-b-white border-b-solid text-3xl text-white mt-2 py-2 rounded-none focus:outline-none"
            type="email"
          />
        </div>
        <div class="flex flex-col mt-8 relative border-b">
          <label
            class="text-white font-light opacity-60 text-2xl"
            for="password-input"
            >Password</label
          >
          <div class="relative">
            <input
              required
              v-model="formData.password"
              id="password-input"
              class="bg-transparent text-3xl text-white py-2 mt-2 w-4/5 rounded-none focus:outline-none"
              :type="showPassword ? 'text' : 'password'"
            />
            <!-- Show Password Button -->
            <span class="show-password">
              <span
                class="text-white font-bold"
                @click="showPassword = true"
                v-show="!showPassword"
                >SHOW</span
              >
              <span
                class="text-white font-bold"
                @click="showPassword = false"
                v-show="showPassword"
                >HIDE</span
              >
            </span>
          </div>
        </div>
        <div class="flex mt-6 text-2xl font-light">
          <a
            @click="view = 'forgotPassword'"
            class="mx-2 text-white underline cursor-pointer font-medium accent-color"
            >Forgot Password?</a
          >
        </div>
        <pm-button
          v-if="!loading"
          type="submit"
          background="solid-light"
          :gutters="true"
          size="small"
          >CONTINUE</pm-button
        >
        <pm-button background="solid-light" size="small" :gutters="true" v-else
          >Please wait...</pm-button
        >
        <div class="flex mx-2 mt-6 text-2xl font-light">
          <p class="text-white opacity-70">Don't have an account?&nbsp;</p>
          <a
            @click="goToRegister"
            class="text-white underline cursor-pointer font-medium accent-color"
            >Sign up!</a
          >
        </div>
      </form>
      <form @submit.prevent="handleFormSubmit" v-show="showForgotPassword">
        <div class="flex flex-col">
          <label
            class="text-white font-light opacity-60 text-2xl"
            for="resetPassword-input"
            >Email Address</label
          >
          <input
            required
            v-model="formData.emailAddress"
            id="resetPassword-input"
            style="border-bottom: 1px white solid"
            class="bg-transparent border-b border-b-white border-b-solid text-3xl text-white mt-4 rounded-none py-2 focus:outline-none"
            type="email"
          />
        </div>

        <pm-button
          v-if="!loading"
          type="submit"
          background="solid-light"
          size="small"
          :gutters="true"
          >SEND PASSWORD RESET EMAIL</pm-button
        >
        <pm-button v-else background="solid-light" size="small" :gutters="true"
          >Please wait...</pm-button
        >

        <div class="flex justify-center text-2xl font-light">
          <p class="text-white mr-2">Remember your password?</p>
          <a
            @click="view = 'login'"
            class="text-white underline font-medium cursor-pointer accent-color"
            >Sign in</a
          >
        </div>
      </form>
      <otp-controls
        v-show="showVerify"
        :emailAddress="formData.emailAddress"
        :otp="otp"
        :loading="loading"
        @verify="handleOTP"
        @resend="resendOTP"
      ></otp-controls>
    </div>
    <v-modal
      :preventClose="false"
      :hideClose="true"
      :modalName="'welcome_modal'"
      classes="modal-content-full"
    >
      <template #content>
        <div
          class="relative p-12 h-screen w-screen flex flex-col items-center justify-center bg-brand-color text-center text-white px-16"
        >
          <div class="text-white absolute top-8 right-8 hidden xl:flex">
            <a
              class="w-full items-center justify-center text-2xl mr-8 flex xl:justify-start xl:text-xl"
              :href="backToProfile"
            >
              <i class="fa-solid fa-delete-left text-4xl pr-2"></i>
              BACK TO PROFILE
            </a>
            <a :href="backToProfile">
              <i class="fa-solid fa-xmark text-4xl cursor-pointer inline"></i>
            </a>
          </div>
          <div class="flex flex-col flex-1 items-center justify-center w-full">
            <img
              :src="profileMeIcon"
              class="mb-8 max-width-90px"
              alt="ProfileMe"
            />
            <h1 class="leading-none mb-12 text-4xl">HELLO!</h1>
            <p
              class="text-center text-gray-200 leading-none mb-8 text-4xl xl:text-xl"
            >
              <span class="text-white font-bold">ProfileMe Users</span>
              can sign in to <br class="xl:hidden" />
              manage their profile & view stats!
            </p>
            <pm-button
              class="solid-light text-4xl max-width-300px mt-0 mb-0"
              background="solid-light"
              :gutters="true"
              size="small"
              @click="closeWelcomeModal"
            >
              Sign In
            </pm-button>
            <div class="border-b pt-14 mb-14 w-screen xl:mx-0 xl:w-24"></div>
            <p class="mb-5 text-4xl xl:text-2xl">Not a ProfileMe user yet?</p>
            <p class="text-gray-200 mb-8 text-4xl xl:text-xl">
              <a
                class="sans-brand w-full text-white font-bold underline"
                target="_blank"
                href="https://profileme.app/request-a-quote/"
              >
                Click here
              </a>
              to request a quote,<br class="xl:hidden" />
              or watch the video below.
            </p>
            <iframe
              width="100%"
              height="170px"
              class="rounded-3xl overflow-hidden border-2 border-white max-width-300px mb-8"
              src="https://www.youtube.com/embed/JWp2q6BCgEY"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </div>
          <a
            class="w-full items-center justify-center text-3xl mr-8 flex xl:hidden xl:justify-start xl:text-xl"
            :href="backToProfile"
          >
            <i class="fa-solid fa-delete-left text-4xl pr-2"></i>
            BACK TO PROFILE
          </a>
        </div>
      </template>
    </v-modal>
  </main>
</template>

<script>
import authService from '~/api/auth';
import { useStore } from 'vuex';
//import GoogleIcon from '~/components/icons/google-icon.vue';
import pmButton from '~/components/ui/pm-button.vue';
import otpControls from '~/components/ui/otp-controls.vue';
import { computed } from 'vue';
import VModal from '~/components/functional/v-modal.vue';
import { $vfm } from 'vue-final-modal';
import axios from 'axios';
import NeedHelp from '~/components/ui/need-help.vue';

export default {
  emits: ['setLoader'],
  setup() {
    const store = useStore();

    return {
      login: (user) => {
        store.commit('supervisor/init', user);
        store.commit('auth/login', user);
      },
      logout: () => {
        store.commit('auth/logout');
        store.commit('supervisor/clear');
      },
      lightLogo: computed(() =>
        store.state.whiteLabel.date
          ? store.state.whiteLabel.lightLogo
          : store.state.whiteLabel.lightIcon
      ),
      companyTitle: computed(() => store.state.whiteLabel.title),
      profileMeIcon: store.state.whiteLabel.lightIcon,
      store
    };
  },
  components: {
    //  GoogleIcon,
    pmButton,
    VModal,
    NeedHelp,
    otpControls
  },
  data: function () {
    return {
      loading: false,
      showPassword: false,
      resendingOTP: false,
      // if both login and forgotPassword is false, show register form
      view: 'sign-in', // login, register, forgotPassword
      googleAuthUrl: process.env.VUE_APP_STRAPI_URL + '/connect/google',
      formData: {
        emailAddress: '',
        password: ''
      },
      backToProfile: ''
    };
  },
  computed: {
    otp() {
      return this.$route.query.otp;
    },
    showSignIn() {
      return this.view === 'sign-in';
    },
    showLogin() {
      return this.view === 'login';
    },
    showForgotPassword() {
      return this.view === 'forgotPassword';
    },
    showVerify() {
      return this.view === 'verify';
    },
    getGoogleAuthUrl: function () {
      if (this.loading) {
        return '#';
      }
      return this.googleAuthUrl;
    },
    formHeader: function () {
      switch (this.view) {
        case 'sign-in':
          return `SIGN IN TO ${this.companyTitle} HUB`.toUpperCase();
        case 'login':
          return 'ENTER YOUR PASSWORD';
        case 'verify':
          return `SIGN IN TO ${this.companyTitle} HUB`.toUpperCase();
        case 'forgotPassword':
          return 'RESET YOUR PASSWORD';
        default:
          return `ACTIVATE ${this.companyTitle} HUB`.toUpperCase();
      }
    }
  },
  mounted() {
    this.handleInitialView();
    this.setInitialValues();
    this.$emit('setLoader', false);
    this.firstTimeVisit();
  },
  methods: {
    registrationCompleted() {
      this.loading = true;
      axios({
        method: 'GET',
        url: `${
          process.env.VUE_APP_STRAPI_URL
        }/profiles/register-completed?email=${encodeURIComponent(
          this.formData.emailAddress
        )}`
      })
        .then((res) => {
          if (res.status == 200) {
            if (res.data.success == false) {
              switch (res.data.message) {
                case 402:
                  this.$showSuccess('Complete Registration.');
                  this.goToRegister();
                  break;
                case 401:
                  this.$showError(
                    'Credentials are incorrect or your not invited to Hub.'
                  );
                  this.$router.push({
                    name: 'Register',
                    query: { view: 'no-user' }
                  });
                  break;
                case 403:
                  this.view = 'verify';
                  this.showVerify = true;
                  this.showLogin = false;
                  this.showPassword = false;
                  this.showSignIn = false;
                  this.showForgotPassword = false;
                  this.resendOTP();
                  break;
                default:
                  break;
              }
            } else {
              this.view = 'login';
            }
          } else {
            this.view = 'login';
          }
        })
        .catch((e) => console.warn(e))
        .finally(() => {
          this.loading = false;
        });
    },
    firstTimeVisit() {
      if (this.$route.query._ftv) {
        this.backToProfile = this.$route.query._ftv;
        // check profileAlias  registerCompleted
        const profileAlias = this.backToProfile.split('/');
        const alias = profileAlias[profileAlias.length - 1];
        console.log('Attempting to check profileAlias', profileAlias, alias);
        axios({
          method: 'GET',
          url: `${process.env.VUE_APP_STRAPI_URL}/profiles/register-completed?alias=${alias}`
        })
          .then((res) => {
            console.log('is register completed', res);
            if (res.status == 200 && res.data == false) {
              // lets take them to registration
              this.goToRegister();
            }
          })
          .catch((e) => console.warn(e));

        if (
          !localStorage.getItem('_ftv') &&
          (window.location.href.includes('profileme') ||
            window.location.href.includes('localhost') ||
            window.location.href.includes('netlify')) &&
          !this.store.state.whiteLabel.hasWhiteLabel
        ) {
          $vfm.show('welcome_modal');
          localStorage.setItem('_ftv', true);
        }
      }
    },
    closeWelcomeModal() {
      $vfm.hide('welcome_modal');
    },
    goToRegister() {
      return this.$router.push({
        name: 'Register',
        query: { email: this.formData.emailAddress }
      });
    },
    async handleOTP(otp) {
      this.loading = true;
      const result = await authService('').verify(
        this.formData.emailAddress,
        otp
      );
      this.loading = false;
      if (result.success) {
        this.$showSuccess('Login verified!');
        this.view = 'login';
        this.showLogin = true;
        this.showVerify = false;
      } else {
        this.$showError(result.message);
      }
    },
    handleInitialView() {
      if (this.$route.query.email && !this.$route.query.verify) {
        this.view = 'login';
      }
      if (this.$route.query.verify) {
        this.view = 'verify';
      }
      if (this.$route.query['forgot-password']) {
        this.view = 'forgotPassword';
      }
    },
    async handleLogin() {
      if (!this.formData.emailAddress.trim()) {
        this.$showError('Please enter your username/email address');
      } else if (!this.formData.password.trim()) {
        this.$showSuccess('Please enter your password');
      } else {
        this.loading = true;
        const result = await authService('').signIn(
          this.formData.emailAddress,
          this.formData.password.trim()
        );
        if (result.success) {
          sessionStorage.setItem('_token', result.message.jwt);
          sessionStorage.setItem('_reload', true);
          if (!result.message.user.confirmed) {
            return this.$router.push({
              name: 'Reset Password',
              query: this.$route.query
            });
          }
          this.$showSuccess('Logged in successfully!');
          return this.$router.push({
            name: 'Main',
            params: { report: 'home' },
            query: this.$route.query
          });
        }
        this.loading = false;
        return this.$showError(result.message);
      }
    },
    async resendOTP() {
      if (!this.formData.emailAddress.trim()) {
        return (this.view = 'register');
      }
      this.resendingOTP = true;
      const result = await authService('').generateLoginOTP(
        this.formData.emailAddress
      );

      this.resendingOTP = false;
      if (!result.success) this.$showError(result.message);
    },
    setInitialValues() {
      if (this.$route.query.email) {
        this.formData.emailAddress = this.$route.query.email;
      }
    },
    async handleForgotPassword() {
      if (!this.formData.emailAddress.trim()) {
        return this.$showError('Please enter an email address');
      }

      this.loading = true;
      const result = await authService('').forgotPassword(
        this.formData.emailAddress
      );
      if (result.success) {
        this.$showSuccess('Password reset email sent!');
        this.loading = false;
        return;
      }
      this.loading = false;
      return this.$showError(result.message);
    },
    handleFormSubmit() {
      switch (this.view) {
        case 'sign-in':
          this.registrationCompleted();
          break;
        case 'login':
          this.handleLogin();
          break;
        case 'forgotPassword':
          this.handleForgotPassword();
          break;

        default:
          break;
      }
    }
  }
};
</script>
<style scoped>
.container {
  width: 22%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.show-password {
  position: absolute;
  top: 4px;
  padding: 5px;
  color: white;
  right: 12px;
  cursor: pointer;
}

.logo-image {
  width: 120px;
}

@media screen and (max-width: 1200px) {
  .container {
    width: 30%;
  }
}

@media screen and (max-width: 1000px) {
  .container {
    width: 40%;
  }
}

@media screen and (max-width: 800px) {
  .container {
    width: 50%;
  }
}

@media screen and (min-width: 768px) {
  .show-password {
    position: absolute;
    top: 8px;
    padding: 5px;
    color: white;
    right: 12px;
    cursor: pointer;
  }
}

@media screen and (max-width: 600px) {
  .container {
    width: 80%;
  }
}

@media screen and (max-width: 350px) {
  .container {
    width: 90%;
  }
}

@media screen and (max-height: 600px) {
  .logo-image {
    width: 100px;
  }
}

@media screen and (max-height: 550px) {
  .logo-image {
    width: 50px;
  }
}
</style>
