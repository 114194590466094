<template>
  <form @submit.prevent="onSubmit">
    <div class="flex flex-col">
      <p class="accent-color font-light text-center text-3xl mb-4">Two-factor authentication required
      </p>
      <p class="text-white font-light text-center text-3xl mb-4">Enter the 4 digit verification code we've sent to
      </p>

      <p
        v-if="emailAddress"
        class="text-white font-light text-center text-3xl mb-16"
      >
        {{ censorEmailAddress(emailAddress) }}
      </p>

      <div class="flex justify-center">
        <input
          v-model="p1"
          @input="moveOnInput"
          class="otp w-24 h-24 mr-8 focus:outline-none text-6xl text-center"
        />
        <input
          v-model="p2"
          @input="moveOnInput"
          class="otp w-24 h-24 mr-8 focus:outline-none text-6xl text-center"
        />
        <input
          v-model="p3"
          @input="moveOnInput"
          class="otp w-24 h-24 mr-8 focus:outline-none text-6xl text-center"
        />
        <input
          v-model="p4"
          @input="moveOnInput"
          class="otp w-24 h-24 focus:outline-none text-6xl text-center"
        />
      </div>
    </div>

    <pm-button
      v-if="!loading"
      type="submit"
      background="solid-light"
      size="small"
      :gutters="true"
    >VERIFY</pm-button>
    <pm-button
      v-else
      background="solid-light"
      size="small"
      :gutters="true"
    >Please wait...</pm-button>

    <div class="flex justify-center text-2xl font-light">
      <p class="text-white mr-2">Did not receive OTP?</p>
      <a
        @click="resendOTP"
        class="text-white underline accent-color font-medium cursor-pointer"
      >{{ resendingOTP ? 'Please wait...' : 'Resend' }}</a>
    </div>
  </form>
</template>

<script>
import pmButton from '~/components/ui/pm-button.vue';
import { chain } from 'lodash';

export default {
  components: {
    pmButton
  },
  props: {
    emailAddress: {
      type: String,
      value: ''
    },
    loading: {
      type: Boolean,
      default: false
    },
    otp: {
      type: String,
      value: ''
    }
  },
  data() {
    return {
      p1: this.otp?.[0] || '',
      p2: this.otp?.[1] || '',
      p3: this.otp?.[2] || '',
      p4: this.otp?.[3] || ''
    };
  },
  watch: {
    otp() {
      this.p1 = this.otp?.[0] || '';
      this.p2 = this.otp?.[1] || '';
      this.p3 = this.otp?.[2] || '';
      this.p4 = this.otp?.[3] || '';
    }
  },
  methods: {
    censorString(str, start, end) {
      if (str)
        return chain(str)
          .split('')
          .fill('*', start || 0, end || str.length)
          .join('')
          .value();
      return '';
    },
    censorEmailAddress(email) {
      return chain([
        chain(email)
          .split('@')
          .first()
          .thru((str) => this.censorString(str, 2)),
        chain(email)
          .split('@')
          .last()
          .thru((str) => this.censorString(str, 1, str.length - 4))
      ])
        .join('@')
        .value();
    },
    onSubmit() {
      this.$emit('verify', this.p1 + this.p2 + this.p3 + this.p4);
    },
    moveOnInput(event) {
      if (event.inputType == 'insertFromPaste') {
        this.p1 = event.target.value[0] || '';
        this.p2 = event.target.value[1] || '';
        this.p3 = event.target.value[2] || '';
        this.p4 = event.target.value[3] || '';
      } else if (
        (event.keyCode === 13 || event.target.value.length == 1) &&
        event.keyCode !== 9 &&
        event.keyCode !== 16
      ) {
        // Focus on the next sibling
        try {
          event.target.nextElementSibling.focus();
        } catch (_) {
          // Empty
        }
      }
    },
    resendOTP() {
      this.$emit('resend');
    }
  }
};
</script>
